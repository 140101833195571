body {
  margin: 0;
  font-family: HelveNueThinNormal, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: black;
}

#parrot-titlebar {
  text-align: center;
  padding-top: 3em;
  margin-bottom: 3em;
}

#parrot-titlebar-title-block {
}

#parrot-titlebar-title-name {
  font-size: xxx-large;
  margin: 0;
}

#parrot-titlebar-title-role {}

#parrot-titlebar-navigation-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3em;
}

.parrot-titlebar-navigation-button {
  font-size: larger;
  padding-bottom: 0.5em;
}

#parrot-main-content {
  padding-left: 10em;
  padding-right: 10em;
}

#parrot-outlinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 6em;
}

.parrot-outlink {
  width: 400px;
  padding: 2em 3em;
}

.parrot-outlink-private-network-text {
    color: red;
    font-style: italic;
    font-size: smaller;
}

.parrot-outlink img {
  max-height: 100%;
  max-width: 100%;
}

.parrot-outlink-main-text {
  font-weight: bolder;
}

.parrot-outlink-additional-text {}

#parrot-blog-post {
  padding: 1em;
  border: 1px dashed grey;
}

#parrot-footer {
  text-align: center;
  margin-top: 8em;
  font-size: small;
}

@font-face {
  font-family: 'HelveNueThinNormal';
  src: url('https://storage.googleapis.com/xprs_resources/fonts/helveticaneuethn-webfont.eot');
  src: url('https://storage.googleapis.com/xprs_resources/fonts/helveticaneuethn-webfont.eot?iefix') format('eot'),
  url('https://storage.googleapis.com/xprs_resources/fonts/helveticaneuethn-webfont.woff') format('woff'),
  url('https://storage.googleapis.com/xprs_resources/fonts/helveticaneuethn-webfont.ttf') format('truetype'),
  url('https://storage.googleapis.com/xprs_resources/fonts/helveticaneuethn-webfont.svg#webfontJR0GaG5Z')
  format('svg');
  font-weight: normal;
  font-style: normal;
}

@media (orientation: portrait) {
  #parrot-outlinks {
    flex-direction: column;
  }
}